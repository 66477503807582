import { useState } from 'react';
import { Card, CardContent, Container, Button, Box, Typography } from '@mui/material';
import { CompanyLogoWide } from '@components/CompanyLogoWide';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { ServerError } from '@components/forms/ServerError';
import spinningGif from '@images/silly_teaser_spinner.gif';
import { getHandleApiError } from '@utils/forms/handleApiError';
import { useCreatePreLaunchLead } from '../hooks/useCreatePreLaunchLead';

const Bullet = () => <>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</>;

type FormData = {
  email: string;
};

export function Splash() {
  const [success, setSuccess] = useState(false);
  const formContext = useForm();
  const {
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = formContext;

  const { trigger, isMutating } = useCreatePreLaunchLead({
    onSuccess: () => {
      setSuccess(true);
      reset();
    },
    onError: getHandleApiError(setError),
  });

  const onSubmit = (data: FormData) => {
    trigger({ ...data, source: 'teaser' });
  };

  return (
    <Container
      sx={{
        my: { xs: 10, sm: 0 },
        display: 'flex',
        textAlign: 'center',
        minHeight: '100vh',
        justifyContent: { sm: 'center' },
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CompanyLogoWide width={200} height={26} />
        <Box sx={{ order: { xs: 0, sm: 8 } }}>
          <Box
            component="img"
            sx={{
              '@media (prefers-reduced-motion: reduce)': {
                display: 'none',
              },
              mt: { xs: 5 },
              width: { xs: 100, sm: 170 },
              height: { xs: 100, sm: 170 },
            }}
            src={spinningGif}
            alt="Launching this summer"
          />
        </Box>
        {/* wrapper used to position gif in different order on mobile */}
        <Typography
          variant="h3"
          sx={{ color: 'primary.main', fontWeight: 800, mt: { xs: 3, sm: 10 } }}
        >
          The all-in-one Medicare platform for brokers.
        </Typography>
        <Typography variant="h5" sx={{ mt: 10 }}>
          Shop, enroll, and track clients from a single platform.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 700, mt: 10, mb: 10 }}>
          All your carriers
          <Bullet />
          FMO neutral
          <Bullet />
          No cost
        </Typography>
      </Container>
      <Card variant="outlined" sx={{ mx: 5, width: { sm: 450 }, boxShadow: 6, mt: 10 }}>
        <CardContent>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: 'primary.main', textAlign: 'center' }}
          >
            Be notified when we launch:
          </Typography>
          <Box sx={{ mx: 10, my: 2 }}>
            <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
              <ServerError sx={{ mb: '15px', mt: '30px' }} errors={errors} />
              <TextFieldElement
                margin="normal"
                required
                fullWidth
                type="email"
                id="email"
                placeholder="Your email"
                name="email"
                autoComplete="email"
                size="large"
              />
              <Button
                size="large"
                type="submit"
                disabled={isMutating}
                fullWidth
                variant="contained"
                sx={{ mt: 5, mb: 4 }}
              >
                Sign up
              </Button>
              {success ? (
                <Typography
                  variant="body1"
                  sx={{
                    mt: 4,
                    color: 'success.700',
                    fontWeight: 'fontWeightBold',
                    textAlign: 'center',
                  }}
                  data-cy="success-msg"
                >
                  Thanks! We&apos;ll email you when HealthSherpa Medicare launches.
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    mt: 4,
                    color: 'grey.900',
                    fontWeight: 'fontWeightLight',
                    textAlign: 'center',
                  }}
                >
                  We&apos;ll only use your email for launch updates, no spam.
                </Typography>
              )}
            </FormContainer>
          </Box>
        </CardContent>
      </Card>
      <Typography
        variant="body2"
        sx={{ fontWeight: 'fontWeightLight', maxWidth: { sm: 600 }, color: 'grey.900', mt: 20 }}
      >
        All information provided on this website is for Agent Use Only and not intended for public
        distribution. Healthsherpa is not connected with or endorsed by the U.S. Government or the
        federal Medicare program.
      </Typography>
    </Container>
  );
}
