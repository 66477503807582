import '@config/sentry';

import { MuiProvider } from '@providers/MuiProvider';
import { SWRProvider } from '@providers/SWRProvider';

import { Splash } from './pages/Splash';

export function App() {
  return (
    <MuiProvider>
      <SWRProvider>
        <Splash />
      </SWRProvider>
    </MuiProvider>
  );
}
